import { all, takeEvery, put } from 'redux-saga/effects';
import actions from './actions';
import {
  simpleQueryFirestoreWithFilter,
  simpleQueryFirestoreWithFilterOrderBy,
  joinCollections,
  firestoreCollectionToArray,
  addPropertyToCollection,
  firestoreCollectionToContainsArray,
  createCleanServiceRows,
  sortJoinCollectionsData,
  chunkFilter,
} from '../../components/utility/firestore.helper';
import { rsf, db } from '@iso/lib/firebase/firebase';
import { call } from 'redux-saga/effects';
import omit from 'lodash/omit';
import { convertCollectionsSnapshotToArray } from '@iso/lib/firebase/firebase.util';
import { getValidNextReservationNumber, getTimezonesArray } from './saga-helper';
import DateFormatter from "../../components/utility/DateFormatter";
import { getAccountIdPrefix } from '@iso/components/library/helpers/local_storage';

const COLLECTION_NAME_BOOKING = 'bookings';
const COLLECTION_NAME_PROPERTIES = 'properties';

function* loadBooking(query) {
  try {
    const filterBooking = { 'booking':  {'value':  parseInt(query.payload.id), 'operator': '==', 'field': 'id' }};
    const data = yield simpleQueryFirestoreWithFilter(getAccountIdPrefix() + COLLECTION_NAME_BOOKING, filterBooking);
    const dataSource = [];
    Object.keys(data).map((item, index) => {
      return dataSource.push({
        ...data[item],
        key: item,
      });
    });
    yield put(actions.loadBookingSuccess(dataSource[0]));
  }
  catch (error) {
    yield put(actions.loadBookingError(error));
  }
}

function* loadBookings(query) {
  try {
    const filtersBooking = query && query.payload.filterBooking;
    const data = yield firestoreCollectionToArray(getAccountIdPrefix() + COLLECTION_NAME_BOOKING, filtersBooking);
    yield put(actions.loadBookingsSuccess(data));
  }
  catch (error) {
    yield put(actions.loadBookingsError(error));
  }
}

function* getNextReservationNumber() {
  try {
    let collectionRef = db.collection(getAccountIdPrefix() + COLLECTION_NAME_BOOKING).orderBy('reservation_number', 'desc').limit(1)
    const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
    const data = yield call(convertCollectionsSnapshotToArray, snapshots);
    const nextReservationNumber = getValidNextReservationNumber(data);
    return nextReservationNumber
  }
  catch (error) {
    yield put(actions.getNextReservationNumberError(error));
  }
}

function* loadBookingsWithProperties(query) {

  try {
    let filtersBooking = query && query.payload.filterBooking;
    let filterBookingQuery = [];
    if(!filtersBooking) filtersBooking={};
    if(filtersBooking.start_date) {
        filterBookingQuery.push(
        {
          field: "start_date",
          operator: ">=",
          value: filtersBooking.start_date.value,
        })
    }
    else {
      let d = new Date();
      filterBookingQuery.push(
        {
          field: "start_date",
          operator: ">=",
          value: DateFormatter.getValueOfDate(d.setMonth(d.getMonth()-6)),
        });
    }

    if(filtersBooking.property) {
      filterBookingQuery.push(
        {
          field: "property_id",
          operator: "==",
          value:filtersBooking.property.value,
        });
    }

    if(filtersBooking.name) {
      filterBookingQuery.push(
          {
            field: "name",
            operator: "==",
            value:filtersBooking.name.value,
          });
    }

    if(filtersBooking.surname) {
      filterBookingQuery.push(
          {
            field: "surname",
            operator: "==",
            value:filtersBooking.surname.value,
          });
    }

    if(filtersBooking.end_date) {
      filterBookingQuery.push(
        {
          field: "start_date",
          operator: "<=",
          value:filtersBooking.end_date.value,
        });
    }

    if(filtersBooking.status) {
      filterBookingQuery.push(
        {
          field: "status",
          operator: filtersBooking.status.operator,
          value:filtersBooking.status.value,
        });
    }
    const filtersProperty = query && query.payload.filterProperty;
    const orderBy = {type:'desc', field:'start_date'};
    let bookingData = yield simpleQueryFirestoreWithFilterOrderBy(getAccountIdPrefix() + COLLECTION_NAME_BOOKING, filterBookingQuery, orderBy);
    let propertyData = yield simpleQueryFirestoreWithFilter(getAccountIdPrefix() + COLLECTION_NAME_PROPERTIES, filtersProperty);
    const data = yield joinCollections(bookingData, propertyData, 'property_id', 'property');
    yield put(actions.loadBookingsWithPropertiesSuccess(data));
  }
  catch (error) {
    console.log(error);
    yield put(actions.loadBookingsWithPropertiesError(error));
  }
}

function* loadArrivalDepartureWithProperties(query) {
  try {
    const filtersBooking = query && query.payload.filterBooking;
    const filtersProperty = query && query.payload.filterProperty;
    const filtersBookingArrival = { 'status': filtersBooking['status'], 'start_date' : filtersBooking['start_date_arrival'], 'end_date' : filtersBooking['end_date_arrival']};
    const filtersBookingDeparture = { 'status': filtersBooking['status'], 'start_date' : filtersBooking['start_date_departure'], 'end_date' : filtersBooking['end_date_departure']};
    const filtersBookingCleanServices = filtersBooking['clean_services'];
    const filtersBookingCleanServicesChunk = chunkFilter(filtersBookingCleanServices, 10);
    let bookingDataArrival = yield firestoreCollectionToArray(getAccountIdPrefix() + COLLECTION_NAME_BOOKING, filtersBookingArrival);
    let bookingDataDeparture = yield firestoreCollectionToArray(getAccountIdPrefix() + COLLECTION_NAME_BOOKING, filtersBookingDeparture);
    let bookingDataClean = [];
    for (let i = 0;  i < filtersBookingCleanServicesChunk.length; i ++) {
      const timezoneArray = getTimezonesArray(filtersBookingCleanServicesChunk[i]);
      const filterTimezoneArray = chunkFilter(timezoneArray, 10);
      for (let j = 0; j < filterTimezoneArray.length; j++) {
        let bookingDataCleanTemp = yield firestoreCollectionToContainsArray(getAccountIdPrefix() + COLLECTION_NAME_BOOKING, filterTimezoneArray[j]);
        bookingDataClean.push(bookingDataCleanTemp);
      }
    }
    bookingDataClean = bookingDataClean.flat();
    let propertyData = yield firestoreCollectionToArray(getAccountIdPrefix() + COLLECTION_NAME_PROPERTIES, filtersProperty);

    bookingDataArrival = addPropertyToCollection(bookingDataArrival, 'arrival');
    bookingDataDeparture = addPropertyToCollection(bookingDataDeparture, 'departure');
    bookingDataClean = createCleanServiceRows(bookingDataClean, filtersBooking['clean_services']);
    bookingDataClean = addPropertyToCollection(bookingDataClean, 'clean');

    const bookingArrivalDeparture = bookingDataArrival.concat(bookingDataDeparture);
    const bookingData = bookingArrivalDeparture.concat(bookingDataClean);
    const bookingDataSorted = sortJoinCollectionsData(bookingData);

    const data = yield joinCollections(bookingDataSorted, propertyData, 'property_id', 'property');

    yield put(actions.loadArrivalDepartureWithPropertiesSuccess(data));
  }
  catch (error) {
    console.log(error);
    yield put(actions.loadArrivalDepartureWithPropertiesError(error));
  }
}

function* deleteBooking({ payload }) {
	const { data, filterBooking } = payload;
	try {
      yield call(rsf.firestore.deleteDocument, getAccountIdPrefix() + COLLECTION_NAME_BOOKING +'/'+ data.key)
      yield put(actions.deleteBookingSuccess());
      yield put({ type: actions.LOAD_BOOKINGS_WITH_PROPERTIES, payload: { filterBooking: filterBooking }});
      yield put(actions.cleanNotificationMessage());
	} catch (error) {
	    yield put(actions.deletePropertyError(error));
	}
}

function* saveBooking({ payload }) {
  const { data, actionName } = payload;
  try {
    switch (actionName) {
      case 'update':
        yield call(rsf.firestore.setDocument, `${getAccountIdPrefix() + COLLECTION_NAME_BOOKING}/${data.key}`, {
          ...omit(data, ['key']),
        });
        yield put(actions.updateBookingSuccess());
        break;
      default:
        let reservationNumber = yield getNextReservationNumber()
        data.reservation_number = reservationNumber
        yield call(rsf.firestore.addDocument, getAccountIdPrefix() + COLLECTION_NAME_BOOKING, {
            ...omit(data, ['user', 'key']),
        });
        yield put(actions.saveBookingSuccess());
        break;
    }
  } catch (error) {
      console.log(error);
      yield put(actions.storeBookingError(error));
  }
}

function* updateStatusBooking({ payload }) {
  const { data } = payload;
  try {
    yield call(rsf.firestore.updateDocument, `${getAccountIdPrefix() + COLLECTION_NAME_BOOKING}/${data.key}`, { status: data.status });
    yield put(actions.updateStatusBookingSuccess());
    yield put(actions.cleanNotificationMessage());
  }
  catch (error) {
    yield put(actions.updateStatusBookingError(error));
    yield put(actions.cleanNotificationMessage());
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_BOOKING, loadBooking),
    takeEvery(actions.LOAD_BOOKINGS, loadBookings),
    takeEvery(actions.LOAD_BOOKINGS_WITH_PROPERTIES, loadBookingsWithProperties),
    takeEvery(actions.LOAD_ARRIVAL_DEPARTURE_WITH_PROPERTIES, loadArrivalDepartureWithProperties),
    takeEvery(actions.SAVE_BOOKING, saveBooking),
    takeEvery(actions.UPDATE_BOOKING, saveBooking),
    takeEvery(actions.UPDATE_STATUS_BOOKING, updateStatusBooking),
    takeEvery(actions.DELETE_BOOKING, deleteBooking),
    takeEvery(actions.GET_NEXT_RESERVATION_NUMBER, getNextReservationNumber),
  ]);
}
