import { rsf, db } from "@iso/lib/firebase/firebase";
import { call } from "redux-saga/effects";
import {
  convertCollectionsSnapshotToMap,
  convertCollectionsSnapshotToArray,
} from "@iso/lib/firebase/firebase.util";
import moment from "moment/moment";

export function* firestoreCollectionToArray(collectionName, filters) {
  let collectionRef = db.collection(collectionName);
  if (filters) {
    Object.entries(filters).forEach((filter) => {
      console.log(filter);
      const filterData = filter[1];
      collectionRef = collectionRef.where(
        filterData.field,
        filterData.operator,
        filterData.value
      );
    });
  }
  const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
  return yield call(convertCollectionsSnapshotToArray, snapshots);
}

export function* firestoreCollectionToContainsArray(collectionName, filters) {
  let collectionRef = db.collection(collectionName);
  collectionRef = collectionRef.where(
    "cleaning_services_dates",
    "array-contains-any",
    filters
  );
  const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
  return yield call(convertCollectionsSnapshotToArray, snapshots);
}

export function* simpleQueryFirestoreWithFilter(collectionName, filters) {
  let collectionRef = db.collection(collectionName);
  if (filters) {
    Object.entries(filters).forEach((filter) => {
      const filterData = filter[1];
      collectionRef = collectionRef.where(
        filterData.field,
        filterData.operator,
        filterData.value
      );
    });
  }
  const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
  return yield call(convertCollectionsSnapshotToMap, snapshots);
}

export function* simpleQueryFirestoreWithFilterOrderBy(
  collectionName,
  filters,
  orderBy
) {
  let collectionRef = db.collection(collectionName);
  if (filters) {
    Object.entries(filters).forEach((filter) => {
      const filterData = filter[1];
      collectionRef = collectionRef.where(
        filterData.field,
        filterData.operator,
        filterData.value
      );
    });
  }
  if (orderBy !== undefined )
    collectionRef = collectionRef.orderBy(orderBy.field, orderBy.type);
  const snapshots = yield call(
      rsf.firestore.getCollection,
      collectionRef
  );
  return yield call(convertCollectionsSnapshotToMap, snapshots);
}

export function* simpleQueryFirestore(collectionName) {
  let collectionRef = db.collection(collectionName);
  const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
  return yield call(convertCollectionsSnapshotToMap, snapshots);
}

export function* existInFirestoreDocument(collectionName, filter) {
  let collectionRef = db.collection(collectionName);
  if (filter) {
    collectionRef = collectionRef.where(
      filter.field,
      filter.operator,
      filter.value
    );
  }
  const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
  return yield call(convertCollectionsSnapshotToArray, snapshots);
}

export function* joinCollections(collection, collectionForeign, key, keyJoin) {
  const data = {};
  const formattedCollectionForeign = Object.entries(collectionForeign).map(
    (doc) => {
      return doc[1];
    }
  );
  Object.entries(collection).forEach((doc) => {
    data[doc[0]] = doc[1];
    data[doc[0]][keyJoin] = formattedCollectionForeign.find(
      (docForeig) => docForeig.id.toString() === doc[1][key]
    );
    if (data[doc[0]][keyJoin] === undefined) {
      delete data[doc[0]];
    }
  });

  return yield data;
}

export const addPropertyToCollection = (collectionData, type) => {
  const dataSource = [];
  collectionData.map((collection) => {
    const collectionTemp = collection;
    collectionTemp["type"] = type;
    collectionTemp["order"] =
      type === "departure" ? collection.end_date : collection.start_date;
    return dataSource.push(collectionTemp);
  });
  return dataSource;
};

const dateBetweenDayFilter = (filters, date) => {
  let find = false;
  filters.forEach( filter => {
    const startDay = moment(filter).startOf("day").valueOf();
    const endDay = moment(filter).endOf("day").valueOf();
    if (startDay <= date && date <= endDay){
      find = true;
    }
  });
  return find;
}

const getItemsFromCleaning = (items, filters, collection) => {
  const dataSource = [];
  const groupByCreatedAt = items.reduce((group, item) => {
    const { createdAt } = item;
    group[createdAt] = group[createdAt] ?? [];
    group[createdAt].push(item);
    return group;
  }, {});
  Object.entries(groupByCreatedAt).forEach(([key, arrayItems]) => {
    if (dateBetweenDayFilter(filters, key)) {
      collection.start_date = parseInt(key);
      const itemNames = [];
      arrayItems.forEach((cleanServiceItem) => {
        itemNames.push(cleanServiceItem.itemName)
      });
      collection.cleaning_services_item = itemNames.join(' , ');
      const bookingCleanService = { ...collection };
      dataSource.push(bookingCleanService);
    }
  });
  return dataSource;
}

export const createCleanServiceRows = (collectionData, filters) => {
  let dataSource = [];
  const uniqueData = collectionData.filter(
      (obj, index) =>
          collectionData.findIndex((item) => item.id === obj.id) === index
  );
  uniqueData.forEach((collection) => {
    const cleanServices = getItemsFromCleaning(collection.cleaning_services_item_list, filters, collection);
    const cleanServicesSpecial = getItemsFromCleaning(collection.cleaning_services_special_item_list, filters, collection);
    dataSource = dataSource.concat(cleanServices);
    dataSource = dataSource.concat(cleanServicesSpecial);
  });
  return dataSource;
};

export const sortJoinCollectionsData = (collectionData) => {
  const sortedCollection = collectionData.sort((a, b) => {
      let orderA = new Date(a.order);
      let orderB = new Date(b.order);
      orderA = String(orderA.getFullYear()) + String(orderA.getMonth()).padStart(2, '0') + String(orderA.getDate()).padStart(2, '0');
      orderB = String(orderB.getFullYear()) + String(orderB.getMonth()).padStart(2, '0') + String(orderB.getDate()).padStart(2, '0');
      return orderA > orderB ? 1 : orderB > orderA ? -1 : (a.property_id > b.property_id) ? 1 : (b.property_id > a.property_id) ? -1 : (b.type === 'departure') ? 1 : (a.type === 'departure') ? -1: 0;
    }
  );

  return sortedCollection;
};

export const chunkFilter = (collectionData, size) => {
  let chunked = [];
  for (let i = 0; i < collectionData.length; i += size) {
    chunked.push(collectionData.slice(i, i + size));
  }
  return chunked;
};
