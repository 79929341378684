import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "./actions";
import { simpleQueryFirestoreWithFilter } from "../../components/utility/firestore.helper";
import { rsf } from "@iso/lib/firebase/firebase";
import omit from "lodash/omit";
import { getAccountIdPrefix } from '@iso/components/library/helpers/local_storage';

const COLLECTION_NAME = 'cities'; // change your collection

function* loadFromFirestore() {
  try {
    const filter = {
      field: "deleted_at",
      operator: "==",
      value: null,
    };
    let data = yield simpleQueryFirestoreWithFilter(getAccountIdPrefix() + COLLECTION_NAME, [filter]);
    yield put(actions.loadFromFireStoreSuccess(data));
  } catch (error) {
    yield put(actions.loadFromFireStoreError(error));
  }
}

function* loadCitiesSelector(query) {
  try {
    const filtersCity = query && query.payload.filterCity;
    const data = yield simpleQueryFirestoreWithFilter(
        getAccountIdPrefix() + COLLECTION_NAME,
        filtersCity
    );
    yield put(actions.loadCitiesSelectorSuccess(data));
  } catch (error) {
    yield put(actions.loadFromFireStoreError(error));
  }
}

function* loadCities(query) {
  try {
    const filtersCity = query && query.payload.filterCity;
    const data = yield simpleQueryFirestoreWithFilter(
        getAccountIdPrefix() + COLLECTION_NAME,
        filtersCity
    );

    yield put(actions.loadCitiesSuccess(data));
  } catch (error) {
    yield put(actions.loadFromFireStoreError(error));
  }
}

function* loadByPropertyFromFireStore({ payload }) {
  try {
    const filter = [
      {
        field: "deleted_at",
        operator: "==",
        value: null,
      },
      {
        field: "propertiesIds",
        operator: "array-contains",
        value: payload.data,
      },
    ];
    let data = yield simpleQueryFirestoreWithFilter(getAccountIdPrefix() + COLLECTION_NAME, filter);
    yield put(actions.loadByPropertyFromFireStoreSuccess(data));
  } catch (error) {
    yield put(actions.loadByPropertyFromFireStoreError(error));
  }
}

function* storeIntoFirestore({ payload }) {
  const { data, actionName } = payload;
  try {
    switch (actionName) {
      case "delete":
        yield call(rsf.firestore.deleteDocument, getAccountIdPrefix() + COLLECTION_NAME + "/" + data.key);
        break;
      case "update":
        yield call(
          rsf.firestore.setDocument,
          `${getAccountIdPrefix() + COLLECTION_NAME}/${data.key}`,
          {
            ...omit(data, ["key"]),
          }
        );
        break;
      default:
        data.id = new Date().getTime();
        yield call(rsf.firestore.addDocument, getAccountIdPrefix() + COLLECTION_NAME, data);
        break;
    }
    yield put({ type: actions.LOAD_FROM_FIRESTORE });
  } catch (error) {
    yield put(actions.saveIntoFireStoreError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_FIRESTORE, loadFromFirestore),
    takeEvery(actions.LOAD_CITIES_SELECTOR, loadCitiesSelector),
    takeEvery(actions.LOAD_CITIES, loadCities),
    takeEvery(
      actions.LOAD_BY_PROPERTY_FROM_FIRESTORE,
      loadByPropertyFromFireStore
    ),
    takeEvery(actions.SAVE_INTO_FIRESTORE, storeIntoFirestore),
  ]);
}
